<template>
    <div class="delete-card">
      <!-- Terug-knop -->
      <div class="back-button" @click="goBack">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"/>
            </svg>
      </div>
  
      <!-- Afbeelding van Adobe XD logo -->
      <div class="icon">
        <img src="https://upload.wikimedia.org/wikipedia/commons/c/c2/Adobe_XD_CC_icon.svg" alt="Adobe XD logo" />
      </div>
  
      <!-- Titel input veld -->
      <div class="input-group">
        <label for="title">Title</label>
        <input type="text" id="title" v-model="title" placeholder="Enter title" />
      </div>
  
      <!-- Verwijder-knop -->
      <button :class="['delete-button', { 'enabled': confirmDelete }]" @click="deleteItem" :disabled="!confirmDelete">
        DELETE PERMANENTLY
      </button>
  
      <!-- Toggle voor bevestiging -->
      <div class="confirmation">
        <input type="checkbox" id="confirm" v-model="confirmDelete" :disabled="!isTitleFilled" />
        <label for="confirm">Yes, I want to permanently delete this connection.</label>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        title: "", // Default leeg
        confirmDelete: false,
      };
    },
    computed: {
      isTitleFilled() {
        // Controleer of de titel niet leeg is, zodat hij niet verwijderd kan worden zonder het te typen
        return this.title.trim().length > 0;
      },
    },
    methods: {
      goBack() {
        this.$router.push({ name: "Tables" });
      },
      deleteItem() {
        if (this.confirmDelete) {
          //delete functie hier
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .delete-card {
    max-width: 400px;
    background-color: #f7f8fa;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 10% auto;
  }
  
  .back-button {
    position: absolute;
    color: #1890ff;
  }
  
  .icon {
    margin: 20px 0;
  }
  
  .icon img {
    width: 80px;
    height: 80px;
  }
  
  .input-group {
    margin: 20px 0;
  }
  
  .input-group label {
    display: flex;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 10px;
    outline: none;
  }
  
  .delete-button {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 8px;
    background-color: #e0e7ff;
    color: #9ca3af;
    font-weight: bold;
    cursor: not-allowed;
    margin-top: 20px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .delete-button.enabled {
    background-color: #ef4444;
    color: white;
    cursor: pointer;
  }
  
  .confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #4b5563;
    margin-top: 15px;
  }
  
  .confirmation input {
    margin-right: 10px;
  }
  </style>
  